import { enableProdMode } from "@angular/core";
enableProdMode();
console.log = function () {}; // no logging of console in production
// export const environment = {
//   production: true,
//   env: 'prd',
//   adminWebAppURL: 'https://app.kubekiosk.com',
//   adminWebAppSignUpURL: 'https://app.kubekiosk.com',
//   demoQueueLink: 'https://pos.kubekiosk.com/queue/registration/demo/demo-queue/ced26c78e2631c700ef9e44132be0f84', 
//   qrCodeAssetRef: './assets/qrcode/qr-code-demo-queue-prd-001.png', 
//   baseAzureFunctionMailerRoute: 'https://fn.kubekiosk.com/api/mailer',
//   code:'awtaFQXKmw4RB6HzyyBoI67FWhHVvctYNDHc7qWgk2T/xu7f0MrmIA=='
// };

export const environment = {
  production: false,
  env: 'sit',
  adminWebAppURL: 'https://kubekiosk-appadmin-dev-001.azurewebsites.net',
  adminWebAppSignUpURL: 'https://kubekiosk-appadmin-dev-001.azurewebsites.net',
  demoQueueLink: 'https://kubekiosk-client-dev-001.azurewebsites.net/queue/registration/demo/demo-queue/ced26c78e2631c700ef9e44132be0f84',
  qrCodeAssetRef: './assets/qrcode/qr-code-demo-queue-sit-001.png',
  baseAzureFunctionMailerRoute: 'https://kube-qms-functions-sit-001.azurewebsites.net/api/mailer',
  code:'1U/rQYPbc1IMH6aQiFoPxD1Lt9/uQBBU3mh7kbqIIc49TKcCdu4o9Q=='
};
