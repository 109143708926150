import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment-actual'; 
import { Injectable } from '@angular/core';

@Injectable()
export class HttpHeaderInterceptor  implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('HTTP Request Intercepted');

    const clonedRequest = req.clone({ 
      headers: new HttpHeaders({
        'x-functions-key':  environment.code
      })
    });
    // Pass the cloned request instead of the original request to the next handle
    console.log('Intercepted HTTP call', clonedRequest);

    return next.handle(clonedRequest);
  }

}
