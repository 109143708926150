import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment-actual'
import { FormBuilder, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { constants, validation_messages } from '../../common/constants';
import { MailService } from '../../common/services/mail.service';
import { Md5 } from 'ts-md5';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit {
  signUpLink: any = environment.adminWebAppSignUpURL;
  submitted = false;
  types = constants.contactTypes;
  contactForm: FormGroup;
  validationMessages = validation_messages;
  submitting = false;
  error = false;
  // default: string = 'Select One';
  minLengthName = 3;
  maxLengthName = 50;
  maxLengthEmail = 320;
  minLengthMessage = 100;
  maxLengthMessage = 2000;
  messagePlaceHolder = constants.placeholderEnquiries;
  constructor(private fb: FormBuilder, private ms: MailService) {
    this.createContactForm();
    // this.contactForm.controls['type'].setValue(this.default, { onlySelf: true });
  }

  createContactForm() {
    this.contactForm = this.fb.group({
      id: new FormControl(new Date().toISOString().substring(0,16) + '-' + Md5.hashStr(Date.now().toString()).toString().substring(0,5).toUpperCase()),  //Generated Ticket ID
      name: new FormControl('', [Validators.required, Validators.minLength(this.minLengthName), Validators.maxLength(this.maxLengthName)]), //name 
      email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(this.maxLengthEmail)]), //email
      type: new FormControl('', [Validators.required]), // type of submissions
      message: new FormControl('', [Validators.required, Validators.minLength(this.minLengthMessage), Validators.maxLength(this.maxLengthMessage)]), // message content of submission
    })
  }

  // make call to SendGrid or Backend Server first to validate.
  sendEmail() {
    console.log(this.contactForm.value);
    this.submitting = true;
    console.log('Sending Email Contact Form');
    console.log('Form Validity: ' + this.contactForm.valid);
    this.contactForm.markAllAsTouched();

    if (!this.contactForm.valid) {
      this.submitting = false;
      return;
    }

    this.ms.sendContactMail(this.contactForm.value).subscribe((resp) => {
      try {
        console.log(resp);
        this.submitting = false;
        this.submitted = true;
      } catch (error) {
      }
    }, (error: HttpErrorResponse) => {
      this.submitting = false;
      this.submitted = false;
      this.error = true;
      console.log(error);
    });;

  }

  trimValues(formControlName, value) {
    console.log('Trimming Value', formControlName, value);
    this.contactForm.controls[formControlName].setValue(value.trim());
  }

  setMessagePlaceHolder(type) {
    console.log(type);
    switch (type) {
      // General Enquiries
      case constants.contactTypes[0]: {
        this.messagePlaceHolder = constants.placeholderEnquiries
        break;
      }
      // Support
      case constants.contactTypes[1]: {
        this.messagePlaceHolder = constants.placeholderSupport
        break;
      }
      // Feedback
      case constants.contactTypes[2]: {
        this.messagePlaceHolder = constants.placeholderFeedback
        break;
      }
      // Feature Request
      case constants.contactTypes[3]: {
        this.messagePlaceHolder = constants.placeholderFeature
        break;
      }
      default: {
        this.messagePlaceHolder = constants.placeholderEnquiries
        break;
      }

    }
  }



  ngOnInit() { }

  navigateToSignUp() {
    if (typeof window !== 'undefined') {
      window.open(this.signUpLink, '_blank');
    }
  }

}
