import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment-actual';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})

export class LandingComponent implements OnInit {
  focus: any;
  focus1: any;

  qrCodeAssetRef = environment.qrCodeAssetRef;
  demoQueueLink: any = environment.demoQueueLink;
  signUpLink: any = environment.adminWebAppSignUpURL;
  qrCodeToggled = false;
  constructor() { }

  ngOnInit() {}

  navigateToDemoQueue() {
    if (typeof window !== 'undefined') {
      window.open(this.demoQueueLink, '_blank');
    }
  }
  toggleQRCode() {
    this.qrCodeToggled = !this.qrCodeToggled;
  }

  navigateToSignUp() {
    if (typeof window !== 'undefined') {
      window.open(this.signUpLink, '_blank');
    }
  }


}
