
export const constants = {
    contactTypes : ['General Enquiries', 'Support', 'Feedback', 'Feature Request'],
    placeholderEnquiries: 'Please enter your general enquiries here',
    placeholderSupport: 'Please enter your request for support for any technical issues related to usage and setup of the product',
    placeholderFeedback: 'Please enter your feedback here',
    placeholderFeature: 'Please enter the features you would like to see in the next product iteration. We may take your suggestion into consideration and contact you for further information.',
}

export const validation_messages = {
    'name': [
        { type: 'required', message: 'Name is required' },
        { type: 'minlength', message: 'Name must be at least 3 characters long' },
        { type: 'maxlength', message: 'Name cannot be more than 50 characters long' }
        // { type: 'pattern', message: 'Your name must contain only numbers and letters.' },
        // { type: 'validUsername', message: 'Your name has already been taken.' }
    ],
    'type': [
        { type: 'required', message: 'Type is required' },
    ],
    'email': [
        { type: 'required', message: 'Email is required' },
        { type: 'minlength', message: 'Email must be at least 3 characters long' },
        { type: 'maxlength', message: 'Email cannot be more than 320 characters long' },
        { type: 'email', message: 'Invalid email' },
    ],
    'message': [
        { type: 'required', message: 'Message is required' },
        { type: 'minlength', message: 'Message must be at least 100 characters long' },
        { type: 'maxlength', message: 'Message cannot be more than 2000 characters long' }    ],

}

// HTTP Methods
export const httpMethods = {
    post: 'POST',
    get: 'GET',
    put: 'PUT',
    delete: 'DELETE'
}
