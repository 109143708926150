import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { httpMethods } from '../constants';
import { environment } from '../../../environments/environment-actual';
import { Injectable } from '@angular/core';
import { constants } from '../constants';
@Injectable({
  providedIn: 'root'
})
export class MailService {

  baseFunctionRoute: string = environment.baseAzureFunctionMailerRoute;
  mode: any;

  constructor(private http: HttpClient) {
  }


  sendContactMail(contactForm: any) {
    console.log('Sending Contact Mail');
    const messageBody = { function:'ContactForm', method: 'post', contactForm: contactForm};
    return this.http.request(httpMethods.post, this.baseFunctionRoute, { body: messageBody, responseType: 'text' });
  }



}