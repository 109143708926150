import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment-actual'
@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.scss']
})

export class PricingComponent implements OnInit {
    signUpLink: any = environment.adminWebAppSignUpURL;

    constructor() { }

    ngOnInit() {}

    navigateToSignUp() {
        if (typeof window !== 'undefined') {
          window.open(this.signUpLink, '_blank');
        }
      }

}
