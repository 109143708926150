import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { SignupComponent } from './pages/signup/signup.component';
import { PricingComponent} from './pages/pricing/pricing.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ContactComponent } from './pages/contact/contact.component';
import {GuideComponent} from './pages/guide/guide.component'
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';
// import { ComponentsComponent } from './components/components.component';
import { PoliciesComponent } from './pages/policies/policies.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';

import { LandingComponent } from './pages/landing/landing.component';

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home',             component: LandingComponent },
    { path: 'privacy',     component: PrivacyComponent },
    { path: 'policies',           component: PoliciesComponent },
    { path: 'nucleoicons',      component: NucleoiconsComponent },
    { path: 'pricing',          component: PricingComponent },
    { path: 'guide',          component: GuideComponent },
    { path: 'contact',          component: ContactComponent },
    { path: '**',           redirectTo: 'home' }
];


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
