import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import {environment} from '../../../environments/environment-actual'
@Component({
    selector: 'app-guide',
    templateUrl: './guide.component.html',
    styleUrls: ['./guide.component.scss']
})

export class GuideComponent implements OnInit, AfterViewInit {
    signUpLink: any = environment.adminWebAppSignUpURL;
    sticky: boolean = false;
    // @ViewChild('stickyMenu') menuElement: ElementRef;
    // menuPosition: any;

    constructor() { }

    ngOnInit() {}

    ngAfterViewInit(){
      // this.menuPosition = 0
      // console.log(this.menuPosition);
  }

    navigateToSignUp() {
        if (typeof window !== 'undefined') {
          window.open(this.signUpLink, '_blank');
        }
      }

      // @HostListener('window:scroll', ['$event'])
      // handleScroll(){
      //   console.log(this.menuElement.nativeElement.offsetTop - this.menuElement.nativeElement.scrollTop);
      //   const windowScroll = window.pageYOffset;
      //   // console.log(this.menuPosition);
      //   console.log(windowScroll);
      //   if(windowScroll >= this.menuPosition + 300){
      //       this.sticky = true;
      //   } else {
      //       this.sticky = false;
      //   }
      // }

}
